<template>
  <div ref="tick" class="tick">
    <div data-repeat="true" aria-hidden="true">
      <span data-view="flip" />
    </div>
  </div>
</template>

<script>
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

const createFlipboard = (el, value) => {
  const board = Tick.DOM.create(el, { value });
  const set = newValue => (board.value = newValue);
  const destroy = () => Tick.DOM.destroy(el);
  return { set, destroy };
};

export default {
  name: "Flip",
  props: { value: { required: true, type: Number } },
  computed: {
    twoDigitsValue: ({ value }) => `${value < 10 ? "0" : ""}${value}`
  },
  watch: {
    twoDigitsValue(newValue) {
      this.board.set(newValue);
    }
  },
  mounted() {
    this.board = createFlipboard(this.$refs.tick, this.twoDigitsValue);
  },
  destroyed() {
    this.board.destroy();
  }
};
</script>

<style scoped>
.tick {
  font-size: 3em;
}
</style>
