
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";
export default Vue.extend({
  name: "DialogEditPlayers",
  props: {
    players: {
      type: Array as PropType<string[]>,
      default: () => [] as string[]
    }
  },
  data() {
    return { newPlayers: [...this.players] };
  },
  computed: {
    isValid() {
      return [...new Set(this.newPlayers)].length === this.newPlayers.length;
    }
  },
  methods: {
    setName(index: number, newName: string) {
      // const newPlayers = [...this.newPlayers];
      this.newPlayers[index] = newName;
      // this.newPlayers = newPlayers;
    },
    removePlayer(index: number) {
      this.newPlayers = [
        ...this.newPlayers.slice(0, index),
        ...this.newPlayers.slice(index + 1)
      ];
    },
    addPlayer() {
      this.newPlayers = [...this.newPlayers, "John Doe"];
    }
  }
});
