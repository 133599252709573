import { get, off, onValue, ref, set } from "firebase/database";
import { FirebaseDB } from "./Setup";
import { FirebaseDatabaseManager, FirebaseState } from "./types";

export const createFirebaseManager = (
  roomId: string
): FirebaseDatabaseManager => {
  const key = `rooms/${roomId}`;
  const databaseRef = ref(FirebaseDB, key);

  const setState = (newState: any) => set(databaseRef, newState);

  const registerCallback = (callback: (data: FirebaseState) => void) =>
    onValue(databaseRef, snapshot => {
      const data = snapshot.val();
      callback(data);
    });

  const getState = () => {
    return get(databaseRef)
      .then(snapshot => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(data);
          return data;
        } else {
          console.log("No data available");
          return null;
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const destroy = () => off(databaseRef);

  return { setState, getState, registerCallback, destroy };
};
