
import Vue from "vue";
import Scoreboard from "./Scoreboard.vue";
import { State, Credits, Score, Scores } from "../types";
import DialogEditPlayers from "./DialogEditPlayers.vue";
import "magic.css/dist/magic.min.css";
import { createFirebaseManager } from "@/firebase/FirebaseDatabaseManager";
// @ts-ignore
import SmokeMachine from "@bijection/smoke";
import { FirebaseState } from "@/firebase/types";

export default Vue.extend({
  name: "HelloWorld",
  props: {
    roomId: {
      type: String,
      required: true
    },
    editMode: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Scoreboard,
    DialogEditPlayers
  },
  data(): State {
    return {
      snackbar: false,
      tab: 0,
      // utiliser des index pour lier les scores au players
      players: ["romain", "michel", "robert"],
      scores: {
        romain: {
          credits: {}
        },
        michel: {
          credits: {}
        },
        robert: {
          credits: {}
        }
      },
      me: "",
      firebaseManager: null
    };
  },
  watch: {
    roomId: {
      handler() {
        this.firebaseManager?.destroy();
        this.firebaseManager = createFirebaseManager(this.roomId);

        const onNewStateReceived = ({ scores, players }: FirebaseState) => {
          console.log("new state received from Firebase:", { scores, players });
          if (!scores || !players) {
            console.warn("no data", { scores, players });
          }

          this.scores = scores || {};
          this.players = players || [];

          this.snackbar = true;
        };

        this.firebaseManager.registerCallback(onNewStateReceived);
      },
      immediate: true
    }
  },
  computed: {
    clickSound: (): any =>
      new Audio(require("@/assets/smoke-weed-everyday.mp3"))
  },
  methods: {
    reset(newPlayers: string[]) {
      this.players = newPlayers;
      this.setScores({});
      this.$emit("close-edit-mode");
    },
    setPlayer(index: number, newName: string) {
      const newPlayers = [...this.players];
      newPlayers[index] = newName;
      this.players = newPlayers;
    },
    countTotalCredits(player: string) {
      const playerScores = this.scores[player]?.credits || {};

      const sum = (acc: number, curr: number) => acc + curr;

      return Object.values(playerScores).reduce(sum, 0);
    },
    getOpponentsCredits({
      scores,
      name
    }: {
      scores: Scores;
      name: string;
    }): Credits {
      const isOpponent = (oppName: string) => oppName !== name;
      const createScoreEntry = (oppName: string) => [
        oppName,
        scores[name]?.credits?.[oppName] || 0
      ];

      const entries = this.players.filter(isOpponent).map(createScoreEntry);

      return Object.fromEntries(entries);
    },
    getCredits(player: string, target: string) {
      return this.scores?.[player]?.credits?.[target] || 0;
    },
    setScores(scores: Scores) {
      this.scores = { ...scores };
      this.updateFirebaseDatabase();
    },
    updateFirebaseDatabase() {
      if (!this.firebaseManager) {
        console.warn("no firebase database");
        return;
      }

      console.log("sending state to Firebase:", this.scores);

      const { scores, players } = this.$data;
      this.firebaseManager.setState({ scores, players });
    },
    addPlayer(name: string) {
      const credits = {};
      this.setScores({ ...this.scores, [name]: { credits } });
    },
    deletePlayer(name: string) {
      const { [name]: deletedPlayer, ...scores } = this.scores;
      this.setScores(scores);
      this.players = this.players.filter(player => player !== name);
    },
    addCredit(name: string, oppName: string, value: number) {
      const currentCredits = this.scores[name]?.credits;

      const credits: Credits = {
        ...currentCredits,
        [oppName]: (currentCredits?.[oppName] || 0) + value
      };

      const scores: Scores = { ...this.scores, [name]: { credits } };

      this.setScores(scores);
    },
    smokeOnButton(scope: string, name: string, oppName: string) {
      // Smore in element
      const selector = [scope, name, oppName].join("-");

      // @ts-ignore
      const el = this.$refs[selector][0].$el;

      el.classList.remove("vanishOut");
      el.classList.add("vanishOut");

      setTimeout(() => {
        el.classList.remove("vanishOut");
      }, 3000);
    },
    smokeCanvas() {
      const canvas = this.$refs["canvas"] as HTMLCanvasElement;
      const ctx = canvas.getContext("2d");

      const party = SmokeMachine(ctx);

      party.addSmoke(500, 500);
      party.start();
    },
    addCreditAndAnimate(
      scope: string,
      name: string,
      oppName: string,
      value: number
    ) {
      this.smokeOnButton(scope, name, oppName);
      // this.smokeCanvas();
      this.addCredit(name, oppName, value);

      if (value < 0) this.playSound();
    },
    playSound() {
      this.clickSound.play().catch(console.warn);
    }
  },
  beforeDestroy() {
    this.firebaseManager?.destroy();
  }
});
