import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const initDatabase = () => {
  // See: https://firebase.google.com/docs/web/learn-more#config-object

  const app = initializeApp({
    databaseURL: "https://black-jack-bj-default-rtdb.firebaseio.com"
  });

  return getDatabase(app);
};

const FirebaseDB = initDatabase();

export { FirebaseDB };
